<template>
  <div class="d-flex  flex-column">
    <router-link :to="dataSport.kindOfSport + '/tour/' + tourid" v-for="game in games" :key="game.ID" class="py-2 game-border-bottom tour-link" :class="dataSport.kindOfSport + '_border'">
      <div class="d-flex">
        <div class="flex-shrink-1 align-self-center text-left game-time-block">
          <div class="font-weight-normal time-start-game text-white">{{game.Time}}</div>
          <MlbGameState :state="game.State" :dataSport="dataSport"></MlbGameState>
        </div>
        <div class="pl-2 w-100">
          <div class="d-flex justify-content-center align-items-center">
            <div class="text-right w-100">
              <div class="font-weight-normal team-font-size text-white" v-if="$i18n.locale === 'ru'">
                {{game.team1_title_short}}
              </div>
              <div class="font-weight-normal team-font-size text-white" v-else>
                {{game.team1_title_short_eng}}
              </div>
              <div class="small text-white-50" v-if="$i18n.locale === 'ru'">{{game.player1_title_short}}</div>
              <div class="small text-white-50" v-else>{{game.player1_title_short_eng}}</div>
            </div>
            <MlbGameScore :game="game" :dataSport="dataSport"></MlbGameScore>
            <div class="text-left w-100">
              <div class="font-weight-normal team-font-size text-white" v-if="$i18n.locale === 'ru'">
                {{game.team2_title_short}}
              </div>
              <div class="font-weight-normal team-font-size text-white" v-else>
                {{game.team2_title_short_eng}}
              </div>
              <div class="small text-white-50" v-if="$i18n.locale === 'ru'">{{game.player2_title_short}}</div>
              <div class="small text-white-50" v-else>{{game.player2_title_short}}</div>
            </div>
          </div>
          <div class="periods-block text-center"><small class="text-nowrap">{{game.Periods}}</small></div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: "MlbStageGames",
    props: ['games','tourid','dataSport'],
    components: {
      MlbGameScore: () => import("@/components/blocks/MlbGameScore.vue"),
      MlbGameState: () => import("@/components/blocks/MlbGameState.vue"),
    },
  }
</script>

<style scoped>
  .team-font-size{
    font-size: .78rem;
  }
  .time-start-game {
    letter-spacing: 2px;
    font-size: 0.8rem;
  }
  .tour-link{
    text-decoration: none;
    transition: all 0.5s;
  }
  .game-time-block {
    max-width: 56px;
    width: 100%;
  }
  .periods-block {
    line-height: 12px;
  }
  .periods-block small {
    letter-spacing: 0px;
    font-size: 0.7rem;
    color: white;
    vertical-align: text-bottom;
  }

</style>